import React from 'react';
import { Typography } from '../../components/atoms';

const getDocument = (filePath: string) => window.open(filePath);

export const reviewSchema = {

  importantStatements: {
    heading: 'Important statements',
    body: (
      <>
        <Typography  variant="body1">
          Breakdown cover is sometimes included with your bank account or when you buy a car, please check if you have breakdown cover already and what you&apos;re 
          covered for. Only buy our cover if you need it.
        </Typography>
        <Typography  variant="body1">
          Please make sure all the information you&apos;ve told us is correct and that your vehicle meets the criteria below. If any of the information you&apos;ve told us 
          is wrong, you may not be covered.
        </Typography>
        <Typography variant="body1">
          Your vehicle(s) must meet the following criteria:
        </Typography>
        <Typography  variant="body1">
          <ul>
            <li className="pb1">
              It&apos;s either a car, three-wheeler, light van, motorbike, motorhome, caravan or trailer
            </li>
            <li className="pb1">
              It&apos;s privately owned and registered in the UK
            </li>
            <li className="pb1">
              It&apos;s never used to carry more than the number of people recommended by the manufacturer
            </li>
            <li className="pb1">
              It&apos;s a standard caravan or trailer which, when loaded, weighs no more than the manufacturer&apos;s specified towing limit and no more than the empty weight 
              of the vehicle towing it.
            </li>
            <li className="pb1">
              It uses an ordinary 50mm tow-ball
            </li>
            <li className="pb1">
              It&apos;s been serviced, looked after and used as recommended by the manufacturer
            </li>
            <li className="pb1">
              It&apos;s got a valid MOT certificate, if required
            </li>
            <li className="pb1">
              It meets all applicable legal requirements and driving laws
            </li>
            <li className="pb1">
              It meets all applicable legal requirements and driving laws
            </li>
            <li className="pb1">
              It must be fit to drive when you take out the policy and at the start of each journey
            </li>
            <li className="pb1">
              It&apos;s never used to carry items or people for money, for example as a taxi or courier service
            </li>
            <li className="pb1">
              It&apos;s never used to vend food or drinks
            </li>
            <li>
              It&apos;s not involved in motor racing, off-road driving, rallies, track days, or duration or speed tests
            </li>
          </ul>
        </Typography>
      </>
    ),
  },
  autoRenewal: {
      heading: 'About automatic renewal',
      body: (
          <>
            <Typography>
              If you&apos;re on auto renewal, we&apos;ll securely store your payment details and use them when  you renew.
            </Typography>
            <Typography>
              You can opt out online or over the phone at any time before your renewal date.
            </Typography>
            <Typography>
              If you&apos;re not on auto renewal, your cover will stop when your policy ends. You&apos;ll need to contact us to stay covered for another year.
            </Typography>
          </>
      ),
  },
  yourRightToCancel: {
    heading: 'Your right to cancel',
    body: (
        <>
          <Typography>
            You can cancel the policy, or any optional cover you&apos;ve added, at any time – just get in touch with us.</Typography>
          <Typography>
            If you&apos;ve just bought the policy or just renewed and you cancel within 14 days of the policy starting or of you receiving the 
            documents, whichever is later (this is the cooling off period), we&apos;ll give you a full refund of your premium, as long as you haven&apos;t made a claim. 
          </Typography>
          <Typography>
            If you cancel after that, as long as you haven&apos;t made a claim since the policy purchase or renewal date, we&apos;ll calculate your refund based on how long your policy had left. 
          </Typography>
          <Typography>
            If you made a claim since the policy purchase or renewal, either within or outside the cooling off period, you can still cancel your policy but no refund will be given. 
          </Typography>
          <Typography>
            It&apos;s your responsibility to let anyone insured under this policy know that this policy has been cancelled. If you cancel your Direct Debit payments, this won&apos;t cancel the 
            policy. We&apos;ll ask you to pay the money you owe. 
          </Typography>
        </>
    ),
  },
  howToComplain: {
      heading: "How to complain",
      body: (
          <>
            <Typography variant="body1">
              You can call us on 0800 051 5318.
            </Typography>
            <Typography variant="body1">
              If you wish to write: Customer Relations Department, Mayday Vehicle Rescue, Churchill Court, Westmoreland Road, Bromley BR1 1DP.
            </Typography>
            <Typography variant="body1">
              You may refer your complaint to the Financial Ombudsman Service (FOS) <a href="https://www.financial-ombudsman.org.uk/" rel="noreferrer" target="_blank">financial-ombudsman.org.uk.</a> You can write to the Ombudsman:
              The Financial Ombudsman Service, Exchange Tower, London E14 9SR. Or telephone them on 0300 123 9123 or 0800 023 4567.
            </Typography>
          </>
      ),
  },
  PersonalCoverPartner: {
    body: (
      <>
        <Typography variant="body1">
          If you have a partner living at your address, they can also use your Personal Cover at no extra cost. If you’d like them to have this cover, enter their details below.
        </Typography>
        <Typography variant="body1" className="pt1">
          Make sure you have permission before providing their personal data to us, and ensure the information you’re providing is correct. If their details change, please notify us immediately.
        </Typography>
      </>
    ),
  },
  renewAuto: {
    body: (
      <>
        <Typography variant="body1">
          Before your policy ends, we&apos;ll send over all your important renewal info.
        </Typography>
        <Typography variant="body1" className="pt1">
          If you&apos;re happy with everything, your cover will continue for another year. You won&apos;t need to get in touch.
        </Typography>
        <Typography variant="body1" className="pt1">
          You&apos;ll still be able to cancel or change your cover if you need to.
        </Typography>
        <Typography variant="body1" className="pt1">
          If you opt out of auto renewal, you&apos;ll have to get in touch before your policy ends to stay covered.
        </Typography>
        <Typography variant="body1" className="pt1">
          Would you like to renew automatically?
        </Typography>
      </>
    ),
  },
  RenewAutomaticallyRadioData: [
    {
      label: 'Yes',
      value: 'true',
    },
    {
      label: 'No',
      value: 'false',
    },
  ],
  InsuranceProductInformation: {
    heading: (
      <>
        <Typography variant="h3" className="pb1">
          Insurance Product Information Document (IPID)
        </Typography>
      </>
    ),
    body: (
      <>
        <Typography variant="body1" className="pb1">
          To help you decide if this policy gives cover that&apos;s right for you, here&apos;s a summary of the main things that will and won&apos;t be covered. 
          It&apos;s not personalised to you and doesn&apos;t contain everything, but full details are available before you buy and will be confirmed in documents 
          we&apos;ll send if you take a policy with us.
        </Typography>
        <Typography variant="body2" className="pb1">
          <a onClick={() => getDocument('pdfs/MayDay-IPID.pdf')} target="_blank" rel="noreferrer" className="anchorLink">Insurance Product Information Document (IPID)</a>
        </Typography>
        <Typography variant="body1">
          This product meets the demands and needs of those who wish to ensure that they have assistance if their vehicle breaks down.
        </Typography>
      </>
    ),
  },
  informationUsage: {
    para1: (
      <>
        <Typography variant="body1">
          Mayday vehicle rescue is provided by Green Flag and underwritten by U K Insurance Limited (UKI). We will use your information to assess your insurance application 
          and the terms on which cover may be offered. We may exchange your information with other insurers, reinsurers or other carefully selected third parties in order to 
          manage your policy and offer you the best possible service. We will also obtain information about you from credit reference agencies to check your credit status and identity. 
          The agencies record our enquiries but your credit standing will not be affected.
        </Typography>
      </>
    ),
    para2: (
      <>
        <Typography variant="body1">
          The information you give us may be used by owned brands within UKI to submit to and check your details with fraud prevention agencies and other organisations. 
          If you provide false or inaccurate information, we will record this. We and other organisation may use and search these records to prevent fraud and money laundering.
        </Typography>
      </>
    ),
    para3: (
      <>
        <Typography variant="body1">
          If you would like full details on how we will use your information, please refer to our <a href="https://u-k-insurance.co.uk/mayday.html" rel="noreferrer" target="_blank" className="anchorLink">Privacy Policy.</a>
        </Typography>
      </>
    ),
  }
};

export default reviewSchema;
